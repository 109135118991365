import './lemdoList.css'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import {
  ActionCreatorWithOptionalPayload,
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
  AsyncThunk,
  Dictionary,
  EntityId
} from '@reduxjs/toolkit'
import { IColumn, ICommandBarItemProps, SelectionMode } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { useNavigate } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store'
import FluentTable from 'components/FluentTable'
import ILEmDOEntity from './lemdoEntity'
import LEmDOEditPanel, { ILEmDOFieldsProps } from './lemdoEditPanel'
import { useSelector } from 'react-redux'
import { IEntityPageObjectResponse } from 'lib/sliceSupport'
import { createCodeColumn, createDatumColumns } from './columnUtils'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import useRoles from 'services/UseRoles'
import { DefaultValues } from 'react-hook-form'
import { VgtSearch } from '../../../../components/VgeSearch/VgtSearch'

export interface ILEmDOListProps<TLEmDOEntity extends ILEmDOEntity> {
  stateIdentifier: string
  getSelectedEntity: (state: RootState) => TLEmDOEntity | undefined
  selectAll: (state: RootState) => TLEmDOEntity[]
  entitySchema: any
  select: ActionCreatorWithOptionalPayload<number | undefined>
  fetchAll: AsyncThunk<IEntityPageObjectResponse<TLEmDOEntity>, void, {}>
  removeMany: ActionCreatorWithPayload<readonly EntityId[], string>
  clearSelection: ActionCreatorWithoutPayload<string>
  modify: ActionCreatorWithPayload<TLEmDOEntity, string>
  add: ActionCreatorWithPayload<TLEmDOEntity, string>
  columnsCreator?: (clickHandler: (id: number) => void) => IColumn[]
  children?: ReactNode
  renderFields?: (props: ILEmDOFieldsProps) => ReactNode
  columnNames?: Dictionary<string>
  extraCommandItems?: ICommandBarItemProps[]
  filter?: boolean
  title: string
  subTitle: string
  handleSubmit?: (entity: TLEmDOEntity) => void
  defaultValues?: DefaultValues<TLEmDOEntity>
}

export const lemdoColumnsCreator = (columnNames: Dictionary<string>, extraColumns: IColumn[], clickHandler: (id: number) => void): IColumn[] => {
  return [createCodeColumn(columnNames, clickHandler)].concat(extraColumns).concat(createDatumColumns())
}

function LemdoList<TLEmDOEntity extends ILEmDOEntity>(props: ILEmDOListProps<TLEmDOEntity>) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const items = useSelector(props.selectAll)
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [selectedItems, setSelectedItems] = useState<number[]>([] as number[])
  const { isAdmin } = useRoles()
  const [searchTerm, setSearchTerm] = useState<string>('')

  useEffect(() => {
    if (items.length === 0) dispatch(props.fetchAll())
  }, [])

  const handleListSelectionChanged = (ids: string[]) => {
    setSelectedItems(ids.map(str => parseInt(str)))
  }

  const handleItemInvoked = (id: number) => {
    dispatch(props.select(id))
    openPanel()
  }

  const refreshItems = () => {
    dispatch(props.fetchAll())
  }

  const clearSelection = () => {
    dispatch(props.clearSelection())
  }

  const removeItems = () => {
    dispatch(props.removeMany(selectedItems))
    clearSelection()
  }

  const columns = props.columnsCreator ? props.columnsCreator(handleItemInvoked) : lemdoColumnsCreator(props.columnNames ?? {}, [], handleItemInvoked)

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
    {
      visible: isAdmin, text: 'Toevoegen', onClick: () => {
        dispatch(props.clearSelection())
        openPanel()
      }, icon: 'Add'
    },
    {
      visible: isAdmin,
      text: 'Verwijderen',
      onClick: () => removeItems(),
      icon: 'Delete',
      disabled: !selectedItems.length
    }
  ], [isAdmin, selectedItems.length])

  const filteredItems = useMemo(() => {
    if (!searchTerm) return items

    return items.filter((item) => {
      const searchTextLower = searchTerm.toLowerCase()
      return (item.naam.toLowerCase().includes(searchTextLower))
    })
  }, [items, searchTerm])

  return (
    <MainTemplate title={props.title} subTitle={props.subTitle} commandItems={commandItems}>
      {props.filter && <VgtSearch onSearch={setSearchTerm} filter={searchTerm} />}
      <LEmDOEditPanel
        stateIdentifier={props.stateIdentifier}
        getSelectedEntity={props.getSelectedEntity}
        dismissPanel={dismissPanel}
        add={props.add}
        modify={props.modify}
        isOpen={isPanelOpen}
        entitySchema={props.entitySchema}
        renderFields={props.renderFields}
        fieldNames={props.columnNames ?? {}}
        title={props.title}
        handleSubmit={props.handleSubmit}
      />
      <div style={{ height: '80vh' }}>
        <FluentTable
          columns={columns}
          items={filteredItems}
          loading={false}
          selectionMode={SelectionMode.multiple}
          onSelectionChanged={handleListSelectionChanged}
          onGetKey={(item: ILEmDOEntity) => item?.id?.toString()}
          onItemInvoked={id => handleItemInvoked(parseInt(id))}
        />
      </div>
    </MainTemplate>
  )
}

export default LemdoList
