export const availableUrls = {
  Dimensies_List: '/beheer/dimensies',
  Dimensies_Edit: '/beheer/dimensies/:id',
  Dimensies_New: '/beheer/dimensies/new',
  Eenheidsoorten: '/beheer/eenheidsoorten',
  Search: '/search',
  Startpagina: '/',
  Eenheiddetailsoorten: '/beheer/eenheidDetailSoorten',
  Ruimtesoorten: '/beheer/ruimtesoorten',
  EenheiddetailsoortenRuimtesoorten: '/beheer/lemdo/eenheidDetailSoorten/ruimteSoorten',
  Defectlocaties: '/beheer/defectlocatie',
  EenheiddetailsoortenDefectlocatiesBinnen: '/beheer/lemdo/eenheidDetailSoorten/defectlocaties',
  EenheiddetailsoortenDefectlocatiesBuiten: '/beheer/lemdo/eenheidDetailSoorten/defectlocatiesbuiten',
  ElementCategorieen: '/beheer/elementCategorieen',
  Elementen: '/beheer/elementen',
  DefectlocatiesElementen: '/beheer/lemdo/defectLocaties/elementen',
  Elementdetails: '/beheer/elementDetails',
  ElementdetailsElementen: '/beheer/lemdo/element/elementDetails',
  Defectsoorten: '/beheer/defectSoorten',
  ElementDefectsoorten: '/beheer/lemdo/elementDefectSoorten',
  Element: '/beheer/lemdo/elementDefectSoort/taken',
  nlsfb: '/beheer/lemdo/nlsfb',
  Taken: '/beheer/lemdo/taken',
  Inspectietaken: '/beheer/lemdo/inspectietaken',
  InspectieSjablonen_List: '/beheer/inspectieSjabloon',
  InspectieSjablonen_Edit: '/beheer/inspectieSjabloon/:id',
  InspectieSjablonen_New: '/beheer/inspectieSjabloon/new',
  Huurcontractbepaling_List: '/beheer/huurcontractbepaling',
  Huurcontractbepaling_Edit: '/beheer/huurcontractbepaling/:id',
  Huurcontractbepaling_New: '/beheer/huurcontractbepaling/new',
  Huurcontractbepaling_Show: '/beheer/huurcontractbepaling/:id/show',
  Vastgoedportefeuille_List: '/beheer/vastgoedportefeuillen',
  Vastgoeddeelportefeuille_List: '/beheer/vastgoeddeelportefeuillen',
  Werkgebied_List: '/beheer/werkgebieden',
  Materiaalsoorten: '/beheer/materiaalSoorten',
  Woningverbetering: '/beheer/lemdo/woningverbetering',
  Onderhoudsspecialismen: '/beheer/lemdo/onderhoudsspecialismen',
  Defectoorzaak: '/beheer/lemdo/defectoorzaak',
  WoningverbeteringToestemmingcodes: '/beheer/lemdo/woningverbeteringtoestemmingcodes',
  DefectlocatiesWoningverbetering: '/beheer/lemdo/woningverbeteringdefectlocaties',
  Werkzaamheden: '/beheer/werkzaamheden',
  Keukens: '/beheer/stamtabellen/keuken',
  Sanitair: '/beheer/stamtabellen/sanitair',
  Voorzieningen: '/beheer/voorzieningen',
  Verwarming: '/beheer/verwarmingen',
  ClassificatieMedia: '/beheer/documentClassificatie',
  Fonticoon: '/beheer/fonticoon',
  Bouwlagen: '/beheer/stamtabellen/bouwlagen',
  TerreinClassificatie: '/beheer/terreinClassificatie',
  Coencad: '/beheer/stamtabellen/coencad',
  Sleuteltypes: '/beheer/sleuteltypen',
  Metertypes: '/beheer/metertypen',
  Verhuuradministratie: '/beheer/queries',
  BasisRegistratiesSearch: '/basisregistraties/zoeken',
  BasisRegistratiesDetails: '/basisregistraties/details/:id',
  Gebouwenclusters: '/beheer/gebouwenclusters',
  Gebouwdelen: '/beheer/gebouwdelen',
  Gebouwdeel: '/beheer/gebouwdeel/:id',
  Vhes_List: '/beheer/vhes',
  Dienstencomponenten: '/beheer/lemdo/dienstencomponenten',
  OnderhoudstakenERP: '/beheer/lemdo/onderhoudstaken',
  RuimtesoortenERP: '/beheer/lemdo/ruimtesoorterp',
  InrichtenTaken: '/beheer/lemdo/inrichtentaken',
  DefectlocatiesRuimtesoorten: '/beheer/lemdo/defectlocatiesruimtesoorten',
  Kostensoorten: '/beheer/lemdo/kostensoorten',
  KostensoortGarantie: '/beheer/lemdo/kostensoortgarantie',
  DefectsoorzakenKostensoorten: '/beheer/lemdo/defectoorzakenKostensoort',
  BetalerKostensoort: '/beheer/lemdo/betalerkostensoorten',
  TenantWoningverbetering: '/beheer/lemdo/tenantwoningverbetering',
  Rechtspersonen_List: '/beheer/relatie/rechtspersonen',
  Rechtspersonen_Edit: '/beheer/relatie/rechtspersonen/:id',
  Rechtspersonen_New: '/beheer/relatie/rechtspersonen/new',
  VvEs: '/beheer/relatie/vves',
  Contactgegevens: '/beheer/relatie/contactgegevens',
  Importeren: '/beheer/imports/importeren',
  Exporteren: '/beheer/imports/exporteren',
  Verwerkingen: '/beheer/verwerkingen',
  VerwerkingStarten: '/beheer/verwerkingen/starten',
  Onderhoudscontracten: '/beheer/onderhoudscontracten',
  Categorieen: '/beheer/categorieen',
  Opnamedetail: '/beheer/opnamedetail',
  Inspectie: '/beheer/tenantInspectietaak',
  BegrippenLijst: '/beheer/begrippenlijst',
  BegrippenLijstViewOnly: '/beheer/begrippenlijst/:viewonly',
  TyperingOnderhoudsbedrijven: '/beheer/typeringonderhoudsbedrijf',
  Contactcategorien: '/beheer/contactCategorien',
  BeheerSelector: '/beheer',
  Gebouwen: '/beheer/gebouw',
  GebouwEdit: '/beheer/gebouwen/:id',
  GebouwenClusterEdit: '/beheer/fysiek/gebouwencluster/:id',
  Dimensiewaarden_List: '/beheer/dimensies/:dimensieId/waarden',
  Dimensiewaarden_Edit: '/beheer/dimensies/:dimensieId/waarden/:id',
  Dimensiewaarden_New: '/beheer/dimensies/:dimensieId/waarden/new',
  ContactCategorie_List: '/beheer/contactCategorien',
  Contactgegevens_List: '/beheer/contactgegevens',
  BegrippenLijst_Update: '/BegrippenLijst/:id',
  Verwarmingen_List: '/beheer/verwarmingen',
  VveDetails: '/beheer/relatie/vves/:id',
  VgeEdit: '/vge/edit/:id',
  VgeFromVhe: '/vge/behorendebijvhe/:id',
  VerwerkingsRegels: '/beheer/verwerkingsregels/:verwerkingen',
  ImportResults: '/beheer/imports/taken',
  ImportElementen: '/beheer/imports/elementenMJOB',
  OnderhoudsContractEdit: '/beheer/onderhoudscontracten/:id',
  Vhes_Edit: '/beheer/vhes/:id',
  Vhes_New: '/beheer/vhes/new',
  VgeSearchResultsLocation: '/vge/searchresults/:query/:type/:locatedIn',
  VgeSearchQueryTypeResults: '/vge/searchresults/:query/:type',
  VgeSearchQueryResults: '/vge/searchresults/:query',
  NotFound: ''
}
