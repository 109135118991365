import { VheListDto, zodiosHooks } from 'api/ApiClient'
import { AvailableRoutes } from 'AppRouter'
import { IColumn } from '@fluentui/react'
import React, { useState } from 'react'
import { createColumn } from 'lib/gridHelper'
import { getPropertyName } from 'lib/interfaceUtils'
import { useNavigate } from 'react-router-dom'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import { z } from 'zod'
import { VgtPagedTable } from '../../components/VgtPagedTable/VgtPagedTable'
import { VgtSearch } from '../../components/VgeSearch/VgtSearch'
import { usePagedParams } from '../../services/usePagedParams'

type VheListDtoType = z.infer<typeof VheListDto>;

export const VheList = () => {
  const navigate = useNavigate()
  const { validatedSearchParams, setPage, setFilter, setOrder } = usePagedParams()
  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const { data, invalidate, isLoading } = zodiosHooks.useGetVhes({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  const { mutate: deleteRows, isLoading: isDeleting } = zodiosHooks.useDeleteVhes({}, {
    onSuccess: () => invalidate(),
    onError: (error) => alert(error)
  })

  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    {
      text: 'Toevoegen',
      onClick: () => navigate(AvailableRoutes.Vhes_New),
      icon: 'Add',
      iconOnly: true,
    },
    {
      text: 'Uit exploitatie',
      onClick: () => {},
      icon: 'Calendar',
      iconOnly: true,
      disabled: isDeleting || selectedIds.length === 0
    },
    {
      text: 'Verwijderen',
      onClick: () => {deleteRows({ itemsToDelete: selectedIds })},
      icon: 'Delete',
      iconOnly: true,
      disabled: isDeleting || selectedIds.length === 0
    }
  ]

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('VHE-Code', getPropertyName<VheListDtoType>('code'), 'M', true),
      createColumn('Eenheiddetailsoort', getPropertyName<VheListDtoType>('detailsoort'), 'XL', false),
      createColumn('Adres', getPropertyName<VheListDtoType>('adres'), 'XL', false),
      createColumn('Postcode', getPropertyName<VheListDtoType>('postcode'), 'S', false),
      createColumn('Woonplaats', getPropertyName<VheListDtoType>('woonplaats'), 'XXL', false)
    ]
  }, [])

  return (
    <MainTemplate title='Verhuureenheden' subTitle='overzicht' commandItems={commandItems}>
      <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter} />
      <VgtPagedTable items={data?.items ?? undefined}
                     height={'203'}
                     pagingInfo={{
                       pageSize: validatedSearchParams.pageSize,
                       currentPage: validatedSearchParams.page,
                       totalCount: data?.totalCount ?? 0,
                       totalPages: data?.totalPages ?? 0,
                       hasNextPage: data?.hasNextPage ?? false,
                       hasPreviousPage: data?.hasPreviousPage ?? false,
                       sortKey: validatedSearchParams.sortKey || 'code',
                       sortDirection: validatedSearchParams.sortDirection || 'asc'
                     }}
                     columns={columns}
                     isLoading={isLoading}
                     getKey={(item) => item?.id}
                     onSelectionChanged={setSelectedIds}
                     onItemInvoked={(e) => navigate(AvailableRoutes.Vhes_Edit.replace(':id', e.id.toString()))}
                     onPageChanged={setPage}
                     onSortChanged={setOrder}
      />

    </MainTemplate>
  )
}