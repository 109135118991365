import { IStackTokens, Pivot, PivotItem, Spinner, SpinnerSize, Stack, StackItem, Text } from '@fluentui/react'
import './gebouwEdit.css'
import InputCard from 'components/InputCard'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getLoadingStatus, IGebouwElementHoeveelheid } from './gebouwenSlice'
import GebouwAdressen from './gebouwAdressen'
import GebouwPercelen from './gebouwPercelen'
import GebouwDelen from './gebouwDelen'
import CollectieveDefectlocaties from './collectieveDefectlocaties'
import GebouwHoeveelheden from './gebouwHoeveelheidList'
import GoogleMapReact from 'google-map-react'
import './marker.css'
import { getGebouwenClustersAsOptions } from '../gebouwenclusters/gebouwenClustersSlice'
import {
  fetchRechtspersonen,
  getRechtspersonenAsOptions
} from 'features/beheer/relaties/rechtspersoon/rechtspersoonSlice'
import { EditMode } from './editPanelGebouwHoeveelheid'
import GebouwOnderhoudsContracten from './onderhoudsContract/gebouwOnderhoudsContracten'
import GebouwOnderhoudsBedrijven from './gebouwOnderhoudsbedrijf'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import { GebouwContactCategorieList } from './gebouwContactCategorie/GebouwContactCategorieList'
import { useBoolean } from '@fluentui/react-hooks'
import { GebouwEditPanel } from './gebouwEditPanel'
import locatie from '../../../vge/icons/Vastgoedtabel_Icons_BAG-locatie.png'
import ReadOnlyField from '../../../../components/ReadOnlyField'
import DocumentList, { DocumentDoelType } from '../../documents/DocumentList'
import { GebouwAddOrUpdateDto, VgeDto, zodiosHooks } from '../../../../api/ApiClient'
import Oppervlakte from '../Oppervlakte/Oppervlakte'
import { CartotheekWizardPanel } from '../../cartotheek/CartotheekPanel'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { HuurcontractbepalingenTooltip } from '../../huurcontractbepaling/HuurcontractbepalingShow'
import {GebouwBestemmingPanel} from "./GebouwBestemmingPanel";

type CreateUpdateType = z.infer<typeof GebouwAddOrUpdateDto>;
type VgeDTO = z.infer<typeof VgeDto>;

const stackTokens: IStackTokens = { padding: '0', childrenGap: '20 20' }

const MarkerComponent = ({ text }: any) => <div className='pin'>{text}</div>

const GebouwEdit: React.FC = () => {
  const { id } = useParams()
  const gebouwId = parseInt(id ?? '0')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [selectedPivot, setSelectedPivot] = useState('gebouw')

  const { data: selectedGebouwen, invalidate } = zodiosHooks.useGetGebouwendetailsId({
    params: {
      id: gebouwId
    }
  }, { enabled: gebouwId !== 0 })

  const { mutate: updateGebouwen } = zodiosHooks.usePutGebouwenId(
    { params: { id: gebouwId } }, {
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { data: documents, invalidate: refetchDocuments } = zodiosHooks.useGetApiDocuments({
    queries: {
      DoelType: DocumentDoelType.gebouw,
      DoelId: gebouwId
    }
  }, { enabled: gebouwId !== 0 })

  const {
    data: hoofdDocumentUrl,
    invalidate: refetchHoofdDocumentUrl
  } = zodiosHooks.useGetApiDocumentshoofdDocumentForDoelTypeDoelTypedoelIdDoelId({
      params: {
        doelType: DocumentDoelType.gebouw,
        doelId: gebouwId
      }
    }, {
      onError: (error) => setError(getTitleAndMessage(error).message),
      enabled: gebouwId !== 0
    }
  )

  const gebouwenClusterOptions = useSelector(getGebouwenClustersAsOptions)
  const rechtspersonenOptions = useSelector(getRechtspersonenAsOptions)
  const loadingStatus = useSelector(getLoadingStatus)
  const hoeveelheden = selectedGebouwen?.hoeveelheden?.filter(e => e.gebouwDeel === null) ?? []
  const [inEditMode, { setTrue: openEditPanel, setFalse: dismissEditPanel }] = useBoolean(gebouwId === 0)
  const [inBestemmingEditMode, { setTrue: openBestemmingEditPanel, setFalse: dismissBestemmingEditPanel }] = useBoolean(false)
  const [error, setError] = React.useState<string>()

  const {
    handleSubmit
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(GebouwAddOrUpdateDto),
    mode: 'onChange',
    defaultValues: {
      code: '',
      naam: '',
      type: 1,
      omschrijving: '',
      gebouwenClusterId: 0,
      registratieniveau: 'STD',
      serviceadresVge: 0
    } as CreateUpdateType
  })

  const selectedCluster = gebouwenClusterOptions.find(option => option.key === selectedGebouwen?.gebouwenClusterId)
  const selectedRegistratieOption = selectedGebouwen?.registratieniveau === 'STD' ? 'Standaard' : 'Werkelijk'
  const selectedGebouwType = selectedGebouwen?.type === 1 ? 'Hoogbouw' : 'Laagbouw'

  const serviceAdres = () => {
    const itemsArray = [] as VgeDTO[]
    if (selectedGebouwen?.vgesMetAdres!) itemsArray.push(...selectedGebouwen?.vgesMetAdres)
    if (selectedGebouwen?.gebouwDelen!) selectedGebouwen?.gebouwDelen.forEach(value => itemsArray.push(...value.vgesMetAdres!))
    const selectedServiceAdres = itemsArray.find(item => item.id === selectedGebouwen?.serviceadresVge)
    if (!selectedServiceAdres) return ''
    return `${selectedServiceAdres?.wpl}, ${selectedServiceAdres?.pc}, ${selectedServiceAdres?.str} ${selectedServiceAdres?.nr}${selectedServiceAdres?.ltr ?? ''}${selectedServiceAdres?.nrT ?? ''}`
  }

  useEffect(() => {
    if (rechtspersonenOptions.length === 0) {
      dispatch(fetchRechtspersonen({ filter: '' }))
    }
  }, [])

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: false },
    { text: 'Wijzigen', onClick: () => openEditPanel(), icon: 'Edit' },
    { text: 'Wijzigen Bestemming', onClick: () => openBestemmingEditPanel(), icon: 'Edit' }
  ], [navigate, openEditPanel])

  const updateSelectedGebouw = data => {
    updateGebouwen(data)
  }

  const calculateCenter = () => {
    if (selectedGebouwen) {
      if (selectedGebouwen?.vgesMetAdres?.length! > 0) {
        return {
          lat: selectedGebouwen?.vgesMetAdres?.[0].lat,
          lng: selectedGebouwen?.vgesMetAdres?.[0].long
        } as GoogleMapReact.Coords
      }

      if (selectedGebouwen?.gebouwDelen?.length! > 0) {
        for (let i = 0; i < selectedGebouwen?.gebouwDelen?.length!; i++) {
          const gebouwdeel = selectedGebouwen?.gebouwDelen?.[i]
          if (gebouwdeel?.vgesMetAdres?.length! > 0) {
            return {
              lat: gebouwdeel?.vgesMetAdres?.[0].lat,
              lng: gebouwdeel?.vgesMetAdres?.[0].long
            } as GoogleMapReact.Coords
          }
        }
      }

      return undefined
    }
  }

  const calculateZoom = () => {
    if (!selectedGebouwen) return 0
    return selectedGebouwen?.vgesMetAdres?.length! > 0 || selectedGebouwen?.gebouwDelen?.length! > 0 ? 18 : 6
  }

  const onSelectPivot = (item: PivotItem | undefined) => {
    const itemKey = item?.props?.itemKey
    if (itemKey === 'gebouw' && selectedPivot !== 'gebouw') {
      refetchHoofdDocumentUrl()
      refetchDocuments()
    }
    setSelectedPivot(itemKey ?? '')
  }

  const photoElement = () => {
    if (hoofdDocumentUrl?.hoofdDocumentUrl) {
      return (
        <img
          style={{
            marginTop: '10px',
            height: '270px',
            width: '397px',
            boxShadow: 'rgb(239 239 239) 0px 0px 9px -1px'
          }}
          src={hoofdDocumentUrl?.hoofdDocumentUrl}
          alt='Geen foto van de locatie gevonden'
        />
      )
    } else {
      return <Text>Er is geen hoofdfoto ingesteld om weer te geven</Text>
    }
  }

  return (
    <MainTemplate title={selectedGebouwen?.naam ? selectedGebouwen.naam : ''} subTitle='Gebouw'
                  commandItems={commandItems}>
      <GebouwEditPanel dismissPanel={dismissEditPanel} isOpen={inEditMode} gebouw={selectedGebouwen}
                       invalidate={invalidate} />
      <GebouwBestemmingPanel dismissPanel={dismissBestemmingEditPanel} isOpen={inBestemmingEditMode} 
      gebouwDetails={selectedGebouwen} invalidate={invalidate} />
      <div style={{ marginLeft: 20, marginTop: 10 }}>
        <Pivot
          styles={{
            link: { padding: '0 8 0 0' },
            linkIsSelected: { padding: '0 8 0 0' },
            itemContainer: { padding: '16px 0 0 0' }
          }}
          style={{ marginBlockEnd: 10 }}
          selectedKey={selectedPivot}
          onLinkClick={onSelectPivot}
        >
          <PivotItem key='gebouw_wijzigen' headerText='Gebouw' itemKey='gebouw'>
            <Stack tokens={stackTokens}
                   style={{ marginLeft: '10px', marginTop: '10px', marginRight: '10px', height: '72vh' }} horizontal>
              <StackItem grow={1}>
                <Stack>
                  <StackItem>
                    <InputCard title='Gebouw' icon={locatie} width='700'>
                      <ReadOnlyField title='Code' value={selectedGebouwen?.code} labelWidth={250} />
                      <ReadOnlyField title='Naam' value={selectedGebouwen?.naam} labelWidth={250} />
                      <ReadOnlyField title='Type bouw' value={selectedGebouwType} labelWidth={250} />
                      <ReadOnlyField title='Bestemming' value={selectedGebouwen?.bestemmingNaam} labelWidth={250} />
                      <ReadOnlyField title='Omschrijving' value={selectedGebouwen?.omschrijving} labelWidth={250} />
                      <ReadOnlyField title='Gebouwencluster' value={selectedCluster?.text} labelWidth={250} />
                      <ReadOnlyField title='Service adres' value={serviceAdres()} labelWidth={250} />
                      <ReadOnlyField title='Service e-mailadres' value={selectedGebouwen?.serviceEmailadres}
                                     labelWidth={250} />
                      <ReadOnlyField title='Service telefoonnummer' value={selectedGebouwen?.serviceTelefoonnummer}
                                     labelWidth={250} />
                      <ReadOnlyField title='Registratieniveau collectieve objecten'
                                     value={selectedRegistratieOption} labelWidth={250} />
                      <HuurcontractbepalingenTooltip huurcontractbepalingen={selectedGebouwen?.huurcontractbepalingen}
                                                     labelWidth={250} />
                      <ReadOnlyField title='Vastgoedportefeuille'
                                     value={selectedGebouwen?.vastgoedportefeuilleNaam} labelWidth={250} />
                      <ReadOnlyField title='Vastgoeddeelportefeuille'
                                     value={selectedGebouwen?.vastgoeddeelportefeuilleNaam} labelWidth={250} />
                      <ReadOnlyField title='Werkgebieden'
                                     value={selectedGebouwen?.werkgebiedNaam} labelWidth={250} />
                      <ReadOnlyField title='Advertentietekst'
                                     value={selectedGebouwen?.advertentietekst}
                                     multiline={true}
                                     autoAdjustHeight={true}
                                     resizable={true}
                                     labelWidth={250}
                      />
                    </InputCard>
                  </StackItem>
                  <StackItem>
                    <ErrorMessageBar error={error} />
                    {photoElement()}
                  </StackItem>
                </Stack>
              </StackItem>
              <StackItem grow={5}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: 'AIzaSyCvaLeRfzfAtc7LJxbk5HhtcvB6WrKtX40', language: 'nl-NL' }}
                  defaultCenter={{ lat: 52.0907374, lng: 5.1214201 }}
                  defaultZoom={6}
                  options={{ mapTypeControl: true, mapTypeId: 'roadmap' }}
                  center={calculateCenter()}
                  zoom={calculateZoom()}
                >
                  {selectedGebouwen?.vgesMetAdres?.map(adres => (
                    <MarkerComponent key={adres.id} lat={adres.lat ?? 51.72835975} lng={adres.long ?? 5.01544399}
                                     text={adres.nr} />
                  ))}
                  {selectedGebouwen?.vgesZonderAdres?.map(adres => (
                    <MarkerComponent key={adres.id} lat={adres.lat ?? 51.72835975} lng={adres.long ?? 5.01544399}
                                     text={adres.nr} />
                  ))}
                  {selectedGebouwen?.gebouwDelen?.map(gebouwdeel =>
                    gebouwdeel.vgesMetAdres?.map(adres => (
                      <MarkerComponent key={adres.id} lat={adres.lat ?? 51.72835975} lng={adres.long ?? 5.01544399}
                                       text={adres.nr} />
                    ))
                  )}
                </GoogleMapReact>
              </StackItem>
            </Stack>
          </PivotItem>
          {selectedGebouwen && (
            <PivotItem key='geb_dl' headerText='Gebouwdelen' itemCount={selectedGebouwen?.gebouwDelen?.length ?? 0}
                       itemKey='geb_dl'>
              <GebouwDelen gebouw={selectedGebouwen?.gebouwDelen!} fetchGebouwdeelItems={invalidate} />
            </PivotItem>
          )}

          <PivotItem key='met_adres' headerText='Adresseerbaar' itemCount={selectedGebouwen?.vgesMetAdres?.length ?? 0}
                     itemKey='met_adres'>
            <GebouwAdressen
              gebouwId={parseInt(id ?? '0')}
              updateSelectedGebouw={handleSubmit(updateSelectedGebouw)}
            />
          </PivotItem>

          <PivotItem key='zonder_adres' headerText='Overig gebouwd'
                     itemCount={selectedGebouwen?.vgesZonderAdres?.filter(x => x.srt !== 'TER').length}
                     itemKey='zonder_adres'>
            <GebouwPercelen
              gebouwId={parseInt(id ?? '0')}
              updateSelectedGebouw={handleSubmit(updateSelectedGebouw)}
              isTerrein={false}
            />
          </PivotItem>

          <PivotItem key='terreinen' headerText='Terreinen'
                     itemCount={selectedGebouwen?.vgesZonderAdres?.filter(x => x.srt === 'TER').length}
                     itemKey='terreinen'>
            <GebouwPercelen
              gebouwId={parseInt(id ?? '0')}
              updateSelectedGebouw={handleSubmit(updateSelectedGebouw)}
              isTerrein={true}
            />
          </PivotItem>

          <PivotItem headerText='Cartotheek' itemKey='cartotheek'>
            <CartotheekWizardPanel gebouwId={selectedGebouwen?.id} />
          </PivotItem>

          <PivotItem key='col_def' headerText='Collectieve defectlocaties' itemKey='col_def'>
            <CollectieveDefectlocaties></CollectieveDefectlocaties>
          </PivotItem>

          <PivotItem key='Oppervlakte' headerText='NEN2580' itemKey='Oppervlakte'>
            <Oppervlakte gebouwId={selectedGebouwen?.id ?? 0} />
          </PivotItem>

          {selectedGebouwen && (
            <PivotItem headerText='Onderhoudscontracten' itemKey='contracten'>
              <GebouwOnderhoudsContracten gebouwId={selectedGebouwen?.id} />
            </PivotItem>
          )}
          {selectedGebouwen && (
            <PivotItem headerText='Onderhoudsbedrijven' itemKey='onderhoudsbedrijven'>
              <GebouwOnderhoudsBedrijven gebouwId={selectedGebouwen?.id} />
            </PivotItem>
          )}
          {selectedGebouwen && (
            <PivotItem headerText='Contactgegevens' itemKey='contactgegevens'>
              <GebouwContactCategorieList gebouwId={selectedGebouwen?.id} />
            </PivotItem>
          )}
          <PivotItem headerText='Conditiemeting' itemCount={hoeveelheden.length} itemKey='elementen'>
            <GebouwHoeveelheden hoeveelheden={hoeveelheden as IGebouwElementHoeveelheid[]} editMode={EditMode.gebouw} />
          </PivotItem>
          {selectedGebouwen && (
            <PivotItem key='gebouw_documenten' headerText='Media' itemCount={documents?.items?.length}
                       itemKey='documents'>
              <DocumentList doelType={DocumentDoelType.gebouw} doelId={selectedGebouwen.id} />
            </PivotItem>
          )}
        </Pivot>
      </div>
      {loadingStatus === 'pending' && (
        <div className='spinnerWrapper'>
          <Spinner size={SpinnerSize.large} />
        </div>
      )}

      <Stack tokens={stackTokens} style={{ marginLeft: '10px', marginTop: '10px', marginRight: '10px' }}>
        <StackItem></StackItem>
        <StackItem></StackItem>
        <StackItem></StackItem>
        <StackItem></StackItem>
      </Stack>
    </MainTemplate>
  )
}

export default GebouwEdit
