import React, { useMemo, useState } from 'react'
import MainTemplate from '../../../containers/pages/PageTemplates/MainTemplate'
import { useNavigate } from 'react-router-dom'
import { DefaultButton, Dropdown, IColumn, IDropdownOption, Label, Stack } from '@fluentui/react'
import useApi from '../../../services/UseApi'
import { createColumn, createCustomRenderedColumn } from '../../../lib/gridHelper'
import { getPropertyName } from '../../../lib/interfaceUtils'
import { VerwerkingDto, zodiosHooks } from '../../../api/ApiClient'
import { getTitleAndMessage } from '../../../services/HandleError'
import { usePagedParams } from '../../../services/usePagedParams'
import { VgtPagedTable } from '../../../components/VgtPagedTable/VgtPagedTable'
import ErrorMessageBar from '../../../components/ErrorMessageBar/ErrorMessageBar'
import { z } from 'zod'
import ClipboardButton from '../../../components/VgtClipboardButton'

type VerwerkingDtoType = z.infer<typeof VerwerkingDto>;

const InvokeVerwerking = () => {
  const navigate = useNavigate()
  const { execute: executeVerwerking } = useApi('verwerking', 'POST', false)
  const [selectedType, setSelectedType] = useState<number>(0)
  const { validatedSearchParams, setPage, setOrder } = usePagedParams()
  const [error, setError] = useState<string>()

  const {
    data: verwerkingItems,
    invalidate: fetchVerwerkingItems,
    isLoading: isFetchingVerwerkingItems
  } = zodiosHooks.useGetVerwerking({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter,
      TypeId: selectedType
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const { data: verwerkinTypes } = zodiosHooks.useGetVerwerkingverwerkingtypes()

  const options = useMemo(() => {
      const result: IDropdownOption[] = []
      if (!verwerkinTypes) return result
      verwerkinTypes?.forEach(row => {
        if (!row.alleTenants && !row.bestandNoodzakelijk)
          result.push({ key: row.id, text: row.naam } as IDropdownOption)
      })
      return result
    }
    , [verwerkinTypes]
  )

  const onSelect = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    if (option) {
      setSelectedType(option.key as number)
      fetchVerwerkingItems()
    }
  }

  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true }
  ]

  const formatToDateTimeString = (date: string | null) => {
    if (!date) return ''
    const parsedDate = new Date(date)
    return parsedDate.toLocaleDateString('nl-NL', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    })
  }

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('ID', getPropertyName<VerwerkingDtoType>('id'), 'S', true),
      createColumn('Beschrijving', getPropertyName<VerwerkingDtoType>('beschrijving'), 'XXL'),
      createCustomRenderedColumn('Gestart op', getPropertyName<VerwerkingDtoType>('gestartOp'), (x: VerwerkingDtoType) => <>{formatToDateTimeString(x.gestartOp)}</>, 'M', false, false),
      createCustomRenderedColumn('Gereed op', getPropertyName<VerwerkingDtoType>('gereedOp'), (x: VerwerkingDtoType) => <>{formatToDateTimeString(x.gereedOp)}</>, 'M', false, false),
      createColumn('Status', getPropertyName<VerwerkingDtoType>('status'), 'L'),
      createColumn('Foutmelding', getPropertyName<VerwerkingDtoType>('foutmelding'), 'XXL'),
      {
        key: 'foutmelding-clipboard',
        name: '',
        fieldName: getPropertyName<VerwerkingDtoType>('foutmelding'),
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        isRowHeader: true,
        onRender: (x: VerwerkingDtoType) => x.foutmelding ?
          <ClipboardButton id={x.id} message={x.foutmelding} /> : null
      }
    ]
  }, [])

  function onUitvoerenClick() {
    if (selectedType === 0) return
    executeVerwerking({ verwerkingstypeId: selectedType })
  }

  return (
    <MainTemplate title='Verwerkingen' subTitle='Uitvoeren' commandItems={commandItems}>
      <ErrorMessageBar error={error} />
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <Label>Verwerkingstype</Label>
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <Dropdown style={{ width: '400px' }} options={options} onChange={onSelect} />
          <DefaultButton onClick={onUitvoerenClick} text={'Uitvoeren'} />
        </Stack>
        <Label style={{ marginTop: 20 }}>Laatste {verwerkingItems?.items?.length} verwerkingen</Label>
      </div>
      <VgtPagedTable
        items={verwerkingItems?.items ?? []}
        isLoading={isFetchingVerwerkingItems}
        height={'203'}
        columns={columns}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: verwerkingItems?.totalCount ?? 0,
          totalPages: verwerkingItems?.totalPages ?? 0,
          hasNextPage: verwerkingItems?.hasNextPage ?? false,
          hasPreviousPage: verwerkingItems?.hasPreviousPage ?? false,
          sortKey: validatedSearchParams.sortKey,
          sortDirection: validatedSearchParams.sortDirection
        }}
        onItemInvoked={() => null}
        onSelectionChanged={() => null}
        getKey={(item) => item?.id}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </MainTemplate>
  )
}

export default InvokeVerwerking