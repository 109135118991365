import {
  CommandBar,
  Dropdown,
  IColumn,
  ICommandBarItemProps,
  IDropdownOption,
  IDropdownStyles,
  IStackTokens,
  Link,
  mergeStyleSets,
  SearchBox,
  Stack
} from '@fluentui/react'
import commandBarStyles from 'styles/commandBarStyles'
import { useNavigate, useParams } from 'react-router-dom'
import React, { useState } from 'react'
import { getPropertyName } from 'lib/interfaceUtils'
import { createColumn, createCustomRenderedColumn } from 'lib/gridHelper'
import { NavigateToBagviewerByVbo, NavigateToKadastraleKaart, NavigateToVge } from 'services/navigationService'
import { VerwerkingRegelDto, zodiosHooks } from '../../../api/ApiClient'
import { z } from 'zod'
import { usePagedParams } from '../../../services/usePagedParams'
import { VgtPagedTable } from '../../../components/VgtPagedTable/VgtPagedTable'

const classNames = mergeStyleSets({
  wrapper: {
    marginTop: 10,
    marginLeft: 10,
    height: '90vh',
    position: 'relative',
    overflow: 'auto'
  }
})

type VerwerkingRegelDtoType = z.infer<typeof VerwerkingRegelDto>;

const VerwerkingsRegels: React.FC = _props => {
  const navigate = useNavigate()
  const { verwerkingen } = useParams()
  const [detailsFilter, setSearchDetailFilter] = useState<string | undefined>('')
  const [objectFilter, setObjectFilter] = useState<string | undefined>('')
  const [vgeFilter, setVgeFilter] = useState<number | undefined>()
  const [vboFilter, setVboFilter] = useState<string | undefined>('')
  const [perceelFilter, setPerceelFilter] = useState<string | undefined>('')
  const [selectedActies, setSelectedActies] = React.useState<string[]>([])
  const [selectedLogtypes, setSelectedLogtypes] = React.useState<string[]>([])
  const { validatedSearchParams, setPage, setOrder } = usePagedParams()

  const {
    data: verwerkingsregelsItems,
    invalidate: fetchVerwerkingsregelsItems,
    isLoading: isFetchingVerwerkingsregelsItems
  } = zodiosHooks.useGetVerwerkingRegels({
    queries: {
      Verwerkingen: verwerkingen,
      LogTypes: selectedLogtypes.length === 0 ? undefined : selectedLogtypes.join(','),
      ActieIds: selectedActies.length === 0 ? undefined : selectedActies.join(','),
      ObjectType: objectFilter,
      VastgoedeenheidId: vgeFilter,
      VerblijfsobjectId: vboFilter,
      KadastraalObjectId: perceelFilter,
      DetailsFilter: detailsFilter,
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  const selectCommandBarItems: ICommandBarItemProps[] = React.useMemo(
    () => [
      {
        key: 'back',
        text: 'Terug',
        iconProps: { iconName: 'Back', className: 'icon' },
        split: false,
        ariaLabel: 'terug',
        onClick: () => navigate(-1)
      }
    ],
    []
  )

  const openVge = (item: VerwerkingRegelDtoType) => NavigateToVge(item.vastgoedeenheidId!)
  const openVbo = (item: VerwerkingRegelDtoType) => item.verblijfsobjectId ? NavigateToBagviewerByVbo({ vboId: item.verblijfsobjectId }) : null
  const openPerceel = (item: VerwerkingRegelDtoType) => item.kadastraalobjectId ? NavigateToKadastraleKaart({ kadastraleAanduiding: item.kadastraalobjectId }) : null

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Id', getPropertyName<VerwerkingRegelDtoType>('id'), 'S', true),
      createCustomRenderedColumn('Logtype', getPropertyName<VerwerkingRegelDtoType>('logTypeId'), (item) =>
        <span>{item.logTypeId === 1 ? 'Fout' : item.logTypeId === 2 ? 'Waarschuwing' : item.logTypeId === 3 ? 'Informatie' : 'Detailinformatie'}</span>, 'S', false),
      createCustomRenderedColumn('Actie', getPropertyName<VerwerkingRegelDtoType>('actieId'), (item) =>
        <span>{item.actieId === 1 ? 'Toegevoegd' : item.actieId === 2 ? 'Gewijzigd' : item.actieId === 3 ? 'Verwijderd' : ''}</span>, 'S'),
      createColumn('Object', getPropertyName<VerwerkingRegelDtoType>('objectType'), 'M'),

      createCustomRenderedColumn('VGE', getPropertyName<VerwerkingRegelDtoType>('vastgoedeenheidId'), (item: VerwerkingRegelDtoType) => item.vastgoedeenheidId ?
        <Link onClick={() => openVge(item)}>{item.vastgoedeenheidId}</Link> : <></>, 'S'),
      createCustomRenderedColumn('Perceel', getPropertyName<VerwerkingRegelDtoType>('kadastraalobjectId'), (item: VerwerkingRegelDtoType) => item.kadastraalobjectId ?
        <Link onClick={() => openPerceel(item)}>{item.kadastraalobjectId}</Link> : <></>, 'M'),
      createCustomRenderedColumn('VBO', getPropertyName<VerwerkingRegelDtoType>('verblijfsobjectId'), (item: VerwerkingRegelDtoType) => item.verblijfsobjectId ?
        <Link onClick={() => openVbo(item)}>{item.verblijfsobjectId}</Link> : <></>, 'M'),

      createCustomRenderedColumn('Details', getPropertyName<VerwerkingRegelDtoType>('details'), (item: VerwerkingRegelDtoType) => item.bestandUrl ?
        <Link href={item.bestandUrl}>Excel-bestand met
          verwerkingsfouten</Link> : <>{item.details}</>, 'XL', false, true)
    ]
  }, [])

  const wrapStackTokens: IStackTokens = { childrenGap: '0 10' }
  const firstStackTokens: IStackTokens = { childrenGap: '10 10' }
  const secondStackTokens: IStackTokens = { childrenGap: '10 10' }
  const onActieChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
    if (item) {
      setSelectedActies(
        item.selected ? [...selectedActies, item.key as string] : selectedActies.filter(key => key !== item.key)
      )
    }
  }
  const onLogtypeChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
    if (item) {
      setSelectedLogtypes(
        item.selected ? [...selectedLogtypes, item.key as string] : selectedLogtypes.filter(key => key !== item.key)
      )
    }
  }

  const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } }
  const ActieOptions = [
    { key: '1', text: 'Toegevoegd' },
    { key: '2', text: 'Gewijzigd' },
    { key: '3', text: 'Verwijderd' }
  ]
  const LogtypeOptions = [
    { key: '1', text: 'Fout' },
    { key: '2', text: 'Waarschuwing' },
    { key: '3', text: 'Informatie' },
    { key: '4', text: 'Detailinformatie' }
  ]

  return (
    <>
      <CommandBar items={selectCommandBarItems} styles={commandBarStyles} />
      <div className={classNames.wrapper}>
        <h2>Verwerkingsregels</h2>
        <Stack horizontal tokens={wrapStackTokens}>
          <Stack tokens={firstStackTokens}>
            <Dropdown placeholder='Filter op logtype' selectedKeys={selectedLogtypes} onChange={onLogtypeChange}
                      multiSelect options={LogtypeOptions} styles={dropdownStyles} />
            <Dropdown placeholder='Filter op acties' selectedKeys={selectedActies} onChange={onActieChange} multiSelect
                      options={ActieOptions} styles={dropdownStyles} />
            <SearchBox value={objectFilter} onChange={(_, newValue) => setObjectFilter(newValue?.trim())}
                       onSearch={fetchVerwerkingsregelsItems} placeholder='Filter op object' />
            <SearchBox value={detailsFilter} onChange={(_, newValue) => setSearchDetailFilter(newValue?.trim())}
                       onSearch={fetchVerwerkingsregelsItems} placeholder='Filter op details' />
          </Stack>
          <Stack tokens={secondStackTokens}>
            <SearchBox
              value={vgeFilter?.toString() || ''}
              onChange={(_, newValue) => {
                const trimmedValue = newValue?.trim()
                const numberValue = !isNaN(parseInt(trimmedValue!)) && trimmedValue !== '' ? parseInt(trimmedValue!) : undefined
                setVgeFilter(numberValue)
              }}
              onSearch={fetchVerwerkingsregelsItems}
              placeholder='Filter op VGE'
            />
            <SearchBox value={perceelFilter} onChange={(_, newValue) => setPerceelFilter(newValue?.trim())}
                       onSearch={fetch} placeholder='Filter op perceel' />
            <SearchBox value={vboFilter} onChange={(_, newValue) => setVboFilter(newValue?.trim())}
                       onSearch={fetchVerwerkingsregelsItems}
                       placeholder='Filter op VBO' />
          </Stack>
        </Stack>
        <Stack horizontal verticalAlign={'center'} tokens={{ padding: 10, childrenGap: 0 }}>
          <span>{verwerkingsregelsItems?.items?.length} verwerkingsregels gevonden.</span>
        </Stack>
        <VgtPagedTable
          items={verwerkingsregelsItems?.items ?? []}
          isLoading={isFetchingVerwerkingsregelsItems}
          height={'203'}
          columns={columns}
          pagingInfo={{
            pageSize: validatedSearchParams.pageSize,
            currentPage: validatedSearchParams.page,
            totalCount: verwerkingsregelsItems?.totalCount ?? 0,
            totalPages: verwerkingsregelsItems?.totalPages ?? 0,
            hasNextPage: verwerkingsregelsItems?.hasNextPage ?? false,
            hasPreviousPage: verwerkingsregelsItems?.hasPreviousPage ?? false,
            sortKey: validatedSearchParams.sortKey,
            sortDirection: validatedSearchParams.sortDirection
          }}
          onItemInvoked={() => null}
          getKey={(item) => item?.id}
          onPageChanged={setPage}
          onSortChanged={setOrder}
        />
      </div>
    </>
  )
}

export default VerwerkingsRegels